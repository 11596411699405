import engineering from '../assets/images/engineering.png';
import service from '../assets/images/service-maintenance.png';
import equipment from '../assets/images/equipment-supply.png';

import orangeBg from '../assets/images/orange-bg.png';
import blueBg from '../assets/images/blue-bg.png';

export const services = [ {
  thumbnail : engineering,
  title     : 'Проектирование',
  desc      : 'Наша компания может разработать проектно-сметную документацию по разделам «Отопление, вентиляция и кондиционирование», «Внутренние водопровод и канализация», «Котельные. Тепломеханическая часть». Мы имеем в штате высококвалифицированных инженеров-проектировщиков, что позволяет нам уверенно браться за объекты любой сложности.',
  background: blueBg
}, {
  thumbnail : equipment,
  title     : 'Поставка оборудования',
  desc      : 'Мы занимаемся поставкой полного спектра оборудования для систем отопления, вентиляции и кондиционирования от ведущих мировых производителей. Все оборудование соответствует высокими требованиями мировых стандартов качества и сопровождается при поставке полным комплектом необходимых документов.',
  background: orangeBg
}, {
  thumbnail : service,
  title     : 'Монтаж и сервисное обслуживание',
  desc      : 'Everest Building Group предоставляет полный спектр услуг по монтажным работам в сфере отопления, вентиляции, кондиционирования, водоснабжения, водоотведения и котельных блоков, а также квалифицированное сервисное обслуживание – контроль над основными параметрами работоспособности, осуществляемый с определенной периодичностью, чтобы система продолжала функционировать безотказно.',
  background: blueBg
} ];