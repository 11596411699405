import aermec from '../assets/images/partners/Aermec.png';
import dab from '../assets/images/partners/DAB.png';
import fiorini from '../assets/images/partners/fiorini.png';
import fujitsu from '../assets/images/partners/FUJITSU.png';
import honeywell from '../assets/images/partners/Honeywell.png';

import lamborgini from '../assets/images/partners/Lamborgini.png';
import mdv from '../assets/images/partners/MDV.png';
import reflex from '../assets/images/partners/reflex.png';
import systemair from '../assets/images/partners/systemair.png';
import carel from '../assets/images/partners/CAREL.png';

import datherm from '../assets/images/partners/Datherm.png';
import frico from '../assets/images/partners/FrICo.png';
import general from '../assets/images/partners/GENERAL.png';
import jaga from '../assets/images/partners/jaga.png';
import lennox from '../assets/images/partners/LENNOX.png';

import mitsubishi from '../assets/images/partners/MITSUBISHI.png';
import rosenberg from '../assets/images/partners/rosenberg.png';
import tecofi from '../assets/images/partners/TECOFI.png';

export const partners = [ {
  logo: aermec,
  name: 'Aermec',
}, {
  logo: dab,
  name: 'DAB',
}, {
  logo: fiorini,
  name: 'fiorini',
}, {
  logo: fujitsu,
  name: 'FUJITSU',
}, {
  logo: honeywell,
  name: 'Honeywell',
}, {
  logo: lamborgini,
  name: 'Lamborgini',
}, {
  logo: mdv,
  name: 'MDV',
}, {
  logo: reflex,
  name: 'reflex',
}, {
  logo: systemair,
  name: 'systemair',
}, {
  logo: carel,
  name: 'CAREL',
}, {
  logo: datherm,
  name: 'Datherm',
}, {
  logo: frico,
  name: 'FrICo',
}, {
  logo: general,
  name: 'GENERAL',
}, {
  logo: jaga,
  name: 'jaga',
}, {
  logo: lennox,
  name: 'LENNOX',
}, {
  logo: mitsubishi,
  name: 'MITSUBISHI',
}, {
  logo: rosenberg,
  name: 'rosenberg',
}, {
  logo: tecofi,
  name: 'TECOFI',
} ];